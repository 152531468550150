import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Поширені Питання про Творчі Петлі
			</title>
			<meta name={"description"} content={"Відкривайте таємниці рукоділля – Ваші питання, наші відповіді"} />
			<meta property={"og:title"} content={"FAQ | Поширені Питання про Творчі Петлі"} />
			<meta property={"og:description"} content={"Відкривайте таємниці рукоділля – Ваші питання, наші відповіді"} />
			<meta property={"og:image"} content={"https://nulvorich.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nulvorich.com/img/4634647.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nulvorich.com/img/4634647.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nulvorich.com/img/4634647.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nulvorich.com/img/4634647.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 70px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
			Поширені Питання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи потрібно мати досвід для участі у курсах?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ні, наші курси розроблені для людей з будь-яким рівнем досвіду, від початківців до досвідчених майстрів.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Які матеріали мені потрібно мати для курсів?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Для більшості курсів всі необхідні матеріали будуть вказані в описі курсу. Деякі базові матеріали, як правило, включають пряжу, голки для в'язання або вишивальні рамки.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можу я спілкуватися з іншими учасниками курсу?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Так, ми заохочуємо спілкування та обмін досвідом між учасниками через нашу творчу спільноту в онлайн-форматі.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Як часто з'являються нові курси?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми регулярно оновлюємо наші програми навчання, вводячи нові курси та майстер-класи, щоб ви могли постійно розвивати свої навички та знання.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи є у вас програма повернення коштів?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Ми пропонуємо гнучку політику повернення коштів для наших курсів, забезпечуючи, що ви можете відчувати себе впевнено при покупці.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Як довго я маю доступ до курсу після його придбання?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Після придбання курсу ви маєте необмежений доступ до навчальних матеріалів, що дозволяє вам повертатися до них у будь-який час.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 80px 0" md-padding="80px 0 80px 0" sm-padding="50px 0 50px 0" quarkly-title="CTA/Lead/Form-3">
			<Box
				min-width="100px"
				min-height="100px"
				padding="80px 80px 80px 80px"
				border-radius="40px"
				md-padding="50px 50px 50px 50px"
				sm-padding="50px 30px 50px 30px"
				background="--color-dark"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--light"
					text-align="center"
					font="normal 700 38px/1.2 --fontFamily-sans"
					lg-margin="0px 0 20px 0"
					sm-font="normal 700 36px/1.2 --fontFamily-sans"
					sm-text-align="left"
				>
					Ваш перший крок до світу творчості
				</Text>
				<Text
					margin="0px 40px 50px 40px"
					text-align="center"
					font="--lead"
					color="rgba(255, 255, 255, 0.8)"
					lg-margin="0px 0 50px 0"
					sm-text-align="left"
				>
				Якщо ваше питання не знайшло відповіді тут, не соромтеся звертатися до нас. Ми приділяємо велику увагу підтримці наших учасників та прагнемо забезпечити, щоб кожен з вас відчував себе впевнено, розпочинаючи свій шлях у навчанні рукоділлю з Творчими Петлями.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					justify-content="center"
					sm-flex-direction="column"
					sm-align-items="center"
				>
					<Button
						background="rgba(247, 251, 255, 0)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						font="normal 400 17px/1.5 --fontFamily-sans"
					>
						Контакти &gt;&gt;
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});